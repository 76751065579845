import React, { useEffect, useState } from 'react'
import { withStyle } from 'baseui'
import { Grid, Row as Rows, Col as Column } from 'components/FlexBox/FlexBox'
import Button from 'components/Button/Button'
import { Plus } from 'assets/icons/PlusMinus'
import { Header, Heading } from 'components/Wrapper.style'
import { gql, useMutation, useQuery } from '@apollo/client'
import { DURATION, useSnackbar } from 'baseui/snackbar'
import { Check } from 'baseui/icon'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import Checkbox, {
  LABEL_PLACEMENT,
  STYLE_TYPE,
} from 'components/CheckBox/CheckBox'
import NotificationsTable from 'components/NotificationsTable/NotificationsTable'
import { OnChangeParams, Select, Value } from 'baseui/select'

export const RUN_PRODUCT_IMPORT_SCRIPT = gql`
  mutation RUN_PRODUCT_IMPORT_SCRIPT(
    $selectedPagesToRevalidate: [String!]!
    $revalidateLowPriorityLinks: Boolean!
  ) {
    runRevalidateScript(
      selectedPagesToRevalidate: $selectedPagesToRevalidate
      revalidateLowPriorityLinks: $revalidateLowPriorityLinks
    )
  }
`

export const STOP_REVALIDATE_SCRIPT = gql`
  mutation STOP_REVALIDATE_SCRIPT {
    stopRevalidateScript
  }
`

export const GET_REVALIDATE_SCRIPT_NOTIFICATIONS = gql`
  query GET_REVALIDATE_SCRIPT_NOTIFICATIONS {
    getRevalidateScriptNotifications {
      image
      primary
      secondary
      script_finished
    }
  }
`

const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',
    ':last-child': {
      marginBottom: 0,
    },
  },
}))

const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px)': {
    alignItems: 'center',
  },
}))

const staticPages = [
  'HOME_PAGE',
  'ABOUT_US_PAGE',
  'BLOG_PAGE',
  'BLOG_POST_PAGES',
  'CONTACT_US_PAGE',
  'TERMS_AND_CONDITIONS_PAGE',
  'PRIVACY_POLICY_PAGE',
]

const productRelatedPages = [
  'PRODUCT_PAGES',
  'CATEGORY_PAGES',
  'STYLE_PAGES',
  'COLLECTION_PAGES',
  'TAG_PAGES',
]

export default function TestScript() {
  const [selectedPagesToRevalidate, setSelectedProfiles] = useState([])
  const [revalidateLowPriorityLinks, setRevalidateLowPriorityLinks] =
    useState(false)

  const [notifications, setNotifications] = useState([])
  const { enqueue } = useSnackbar()

  const [scriptLoading, setScriptLoading] = useState(false)

  //* ==== Mutation to edita data in database
  const [
    runRevalidateScript,
    {
      data: mutationData,
      called,
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(RUN_PRODUCT_IMPORT_SCRIPT, {
    onError: (e) => {
      console.log('Mutation error!', e)
      console.log('Extracted error!', e.graphQLErrors)
      enqueue(
        {
          message: 'Error!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
          },
        },
        DURATION.short,
      )
    },
    onCompleted: (e) => {
      enqueue(
        {
          message: 'Script succesfuly started!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
          },
        },
        DURATION.short,
      )
      //===== Start polling when the mutation finished loading...
      startPolling(5000) // Every 5 seconds we look for new notifications
    },
  })

  //* ==== Mutation to edita data in database
  const [
    stopProductImportScript,
    {
      data: mutationDataScriptStop,
      loading: mutationLoadingScriptStop,
      error: mutationErrorScriptStop,
    },
  ] = useMutation(STOP_REVALIDATE_SCRIPT, {
    onError: (e) => {
      console.log('Mutation error!', e)
      console.log('Extracted error!', e.graphQLErrors)
      enqueue(
        {
          message: 'Error!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
          },
        },
        DURATION.short,
      )
    },
    onCompleted: (e) => {
      enqueue(
        {
          message: 'Script succesfuly stopped!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
          },
        },
        DURATION.short,
      )
      //===== Start polling when the mutation finished loading...
      // handleScriptFinish()
      // Handle the finis with notifications in the backend...
    },
  })

  const {
    data: notificationData,
    loading,
    error,
    startPolling,
    stopPolling,
  } = useQuery(GET_REVALIDATE_SCRIPT_NOTIFICATIONS, {
    // pollInterval: 5000,
  })

  const handleScriptStart = (e) => {
    e.preventDefault()
    e.stopPropagation()

    runRevalidateScript({
      variables: {
        revalidateLowPriorityLinks: revalidateLowPriorityLinks,
        selectedPagesToRevalidate: selectedPagesToRevalidate,
      },
    })

    setScriptLoading(true)

    setNotifications((oldArray) => {
      if (oldArray.length > 200) {
        oldArray.length = 200
      }

      const startNotification = {
        image: null,
        primary: 'Initializing Revalidate script',
        secondary: 'Calculating (script will begin shortly)...',
      }

      return [startNotification]
    })
  }

  const handleScriptFinish = () => {
    //----
    console.log('handleScriptFinish!!!')
    stopPolling()
    setScriptLoading(false)
  }

  const handleScriptStop = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const stopNotification = {
      image: null,
      primary: '======= STOPING SCRIPT ===========',
      secondary: 'Wait for processes to stop in the backend...',
    }
    setNotifications((oldArray) => {
      if (oldArray.length > 200) {
        oldArray.length = 200
      }
      return [stopNotification, ...oldArray]
    })

    startPolling(5000)
    console.log('Trying to stop script')
    //--- Initiate stop script query...
    stopProductImportScript()
  }

  useEffect(() => {
    console.log('notificationData :>> ', notificationData)

    if (notificationData) {
      for (const message of notificationData.getRevalidateScriptNotifications) {
        //We loop all messages and search if scrip_finished
        if (message.script_finished) {
          //--- Script has finished
          handleScriptFinish()
        } else {
          if (scriptLoading === false) {
            setScriptLoading(true)
            startPolling(5000)
          }
        }
      }

      if (scriptLoading) {
        // We limit the length of Notifications array, to avoid browser lags...
        setNotifications((oldArray) => {
          if (oldArray.length > 200) {
            oldArray.length = 200
          }
          return [
            ...notificationData.getRevalidateScriptNotifications,
            ...oldArray,
          ]
        })
      }
    }
  }, [notificationData])

  const handleOptionChange = (option, value) => {
    // console.log("option ->", option);
    // console.log("value ->", value);
    if (value) {
      // if checked, add to the list
      setSelectedProfiles([...selectedPagesToRevalidate, option])
    } else {
      // if unchecked
      var filtered = selectedPagesToRevalidate.filter(
        function (value, index, arr) {
          return value != option
        },
      )
      setSelectedProfiles(filtered)
    }
  }

  //===== CHOICES
  const staticPageOptions = staticPages.map((option, i) => (
    <PageOption key={i} option={option} onChange={handleOptionChange} />
  ))

  const productRelatedPageOptions = productRelatedPages.map((option, i) => (
    <PageOption key={i} option={option} onChange={handleOptionChange} />
  ))

  // console.log('notifications :>> ', notifications)
  // console.log('Query data ->', notificationData)

  // console.log('scriptLoading :>> ', scriptLoading)

  if (error) return <p>Query Error! {error.message}</p>
  if (mutationError) return <p>Mutation Error! {mutationError.message}</p>

  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          <Header
            style={{
              marginBottom: 30,
              boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
            }}
          >
            <Col md={6}>
              <Heading>Revalidate script</Heading>
            </Col>

            <Col md={6}>
              <Button
                onClick={(e) => handleScriptStop(e)}
                // disabled={!scriptLoading}
                // isLoading={scriptLoading}
                // startEnhancer={() => <Plus />}
                kind="secondary"
                // disabled={true}
                overrides={{
                  BaseButton: {
                    style: ({ $theme, $size, $shape }) => {
                      return {
                        width: '45%',
                        borderTopLeftRadius: '3px',
                        borderTopRightRadius: '3px',
                        borderBottomLeftRadius: '3px',
                        borderBottomRightRadius: '3px',
                        marginRight: '4%',
                        backgroundColor: $theme.colors.red400,
                        color: $theme.colors.white,
                        // outline: `${$theme.colors.red400} solid`,
                      }
                    },
                  },
                }}
              >
                Stop
              </Button>

              <Button
                onClick={(e) => handleScriptStart(e)}
                isLoading={scriptLoading}
                startEnhancer={() => <Plus />}
                overrides={{
                  BaseButton: {
                    style: ({ $theme, $size, $shape }) => {
                      return {
                        width: '50%',
                        borderTopLeftRadius: '3px',
                        borderTopRightRadius: '3px',
                        borderBottomLeftRadius: '3px',
                        borderBottomRightRadius: '3px',
                      }
                    },
                  },
                }}
              >
                Start script
              </Button>
            </Col>

            <Col md={12}>
              <div
                style={{
                  marginTop: 10,
                  borderTop: '1px solid #eee',
                  width: '100%',
                }}
              >
                <Row start="md" middle="md">
                  <Col md={6}>
                    <p>
                      <strong>Static pages</strong>
                    </p>
                    {staticPageOptions}
                  </Col>
                  <Col md={6}>
                    <p>
                      <strong>Product related pages</strong>
                    </p>
                    {productRelatedPageOptions}
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={12}>
              <div
                style={{
                  marginTop: 10,
                  borderTop: '1px solid #eee',
                  width: '100%',
                }}
              >
                <Row start="md" middle="md">
                  <Col>
                    <p>
                      Do you want to revalidate low priority links? {' ----> '}
                      <strong>
                        {revalidateLowPriorityLinks ? 'YES' : 'NO'}
                      </strong>
                    </p>
                    <p>e.g.: website.com/fr/page_in_other_language </p>
                  </Col>
                  <Col>
                    <Checkbox
                      checked={revalidateLowPriorityLinks}
                      checkmarkType={STYLE_TYPE.toggle_round}
                      onChange={(e) =>
                        setRevalidateLowPriorityLinks(
                          !revalidateLowPriorityLinks,
                        )
                      }
                      labelPlacement={LABEL_PLACEMENT.left}
                      overrides={{
                        Root: {
                          style: {
                            float: 'right',
                          },
                        },
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </Col>

            {scriptLoading && <ProgressBar infinite={true} value={100} />}
          </Header>

          <NotificationsTable data={notifications} />
        </Col>
      </Row>
    </Grid>
  )
}

const PageOption = ({ option, onChange }) => {
  const [checked, setChecked] = useState(false)

  const handleChange = (e) => {
    setChecked(!checked)
    onChange(option, !checked)
  }
  return (
    <div>
      <Checkbox
        checked={checked}
        onChange={(e) => handleChange(e)}
        labelPlacement={LABEL_PLACEMENT.right}
      >
        {option}
      </Checkbox>
    </div>
  )
}
