import React, { useCallback, useEffect, useState } from 'react'
// import dayjs from "dayjs";
import { withStyle, createThemedUseStyletron } from 'baseui'
import { Grid, Row as Rows, Col as Column } from 'components/FlexBox/FlexBox'
import { useDrawerDispatch } from 'context/DrawerContext'
import Button from 'components/Button/Button'
import { Plus } from 'assets/icons/PlusMinus'
import { Header, Heading } from 'components/Wrapper.style'
import { gql, useMutation, useSubscription } from '@apollo/client'
import { DURATION, useSnackbar } from 'baseui/snackbar'
import { Check } from 'baseui/icon'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher'
import LanguageSwitcherProfile from 'components/LanguageSwitcher/LanguageSwitcherProfile'
import Checkbox, {
  LABEL_PLACEMENT,
  STYLE_TYPE,
} from 'components/CheckBox/CheckBox'
import NotificationsTable from 'components/NotificationsTable/NotificationsTable'
import { useConfirmation } from 'context/ConfirmationServiceContext'
import { FieldDetails } from 'containers/DrawerItems/DrawerItems.style'

// TODO ix Blog Post Translate
// TODO Add a settings option to allow either overwrite or skip already translated profiles.

export const RUN_LANGUAGE_TRANSLATE_SCRIPT = gql`
  mutation RUN_LANGUAGE_TRANSLATE_SCRIPT(
    $translateFrom: Language!
    $translateTo: Language!
    $selectedProfiles: [String!]!
    $overwrtieExistingProfiles: Boolean!
  ) {
    runLanguageTranslateScript(
      translateFrom: $translateFrom
      translateTo: $translateTo
      selectedProfiles: $selectedProfiles
      overwrtieExistingProfiles: $overwrtieExistingProfiles
    )
  }
`

export const LANGUAGE_SCRIPT_SUBSCRIPTION = gql`
  subscription LANGUAGE_SCRIPT_SUBSCRIPTION {
    languageScriptSubscription {
      image
      primary
      secondary
    }
  }
`

type CustomThemeT = { red400: string; textNormal: string; colors: any }
const themedUseStyletron = createThemedUseStyletron<CustomThemeT>()

const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',
    ':last-child': {
      marginBottom: 0,
    },
  },
}))
const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px)': {
    alignItems: 'center',
  },
}))

const availableLanguageProfiles = [
  'PRODUCT_PROFILE',
  'ADVANCED_PRODUCT_PROFILE',
  'CATEGORY_PROFILE',
  'STYLE_PROFILE',
  'COLLECTION_PROFILE',
  'COLOR_PROFILE',
  'MANUFACRTURER_PROFILE',
  'SHIPPING_METHOD_PROFILE',
  'PAYMENT_METHOD_PROFILE',
  'SHOP_PROFILE',
  // "BLOG_PROFILE", // TODO currently not working due to content JSON

  'FABRIC_GROUP_PROFILE',
  'ADDITIONAL_COMPONENT_GROUP_PROFILE',
  'ADDITIONAL_COMPONENT_PROFILE',
  'CONTENT_BLOCK_PROFILE',
]

export default function LanguageTranslateScript() {
  const [translateFromLang, setTranslateFromLang] = useState('lt')
  const [translateToLang, setTranslateToLang] = useState('en')
  const [overwrtieExistingProfiles, setOverwrtieExistingProfiles] = useState(
    false,
  )
  const [selectedProfiles, setSelectedProfiles] = useState([])
  const [notifications, setNotifications] = useState([])

  const [scanningPage, setScanningPage] = useState(0)
  const { enqueue } = useSnackbar()
  const confirm = useConfirmation()

  // ==== NOTIFICATIONS SUBSCRIPTION =====
  // const {
  //   data: subscriptionData,
  //   loading: subscriptionLoading,
  // } = useSubscription(LANGUAGE_SCRIPT_SUBSCRIPTION)

  // useEffect(() => {
  //   // Update incoming data of subscriptions
  //   if (subscriptionData?.languageScriptSubscription) {
  //     // We limit the length of Notifications array, to avoid browser lags...
  //     setNotifications((oldArray) => {
  //       if (oldArray.length > 100) {
  //         oldArray.length = 100
  //       }
  //       return [subscriptionData.languageScriptSubscription, ...oldArray]
  //     })
  //   }
  //   // console.log("Subscription data ->", notifications);
  // }, [subscriptionData])

  //* ==== Mutation to edita data in database
  const [
    runLanguageTranslateScript,
    {
      data: mutationData,
      called,
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(RUN_LANGUAGE_TRANSLATE_SCRIPT, {
    onError: (e) => {
      // For mutation errors...
      console.log('Mutation error!', e)
      console.log('Extracted error!', e.graphQLErrors)
      enqueue(
        {
          message: 'Error!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
          },
        },
        DURATION.short,
      )
    },
    onCompleted: (e) => {
      // Handle the success case.
      enqueue(
        {
          message: 'Saving succesful!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
          },
        },
        DURATION.short,
      )
    },
    // Not sure if needed, after we changed - fetchPolicy: "cache-and-network", on all queries
    refetchQueries: [
      // 'GET_CATEGORIES',
      // 'GET_CATEGORY',
      // 'GET_CATEGORIES_FOR_PRODUCT_SELECT',
      // 'GET_STYLES',
      // 'GET_STYLE',
      // 'GET_STYLES_FOR_PRODUCT_SELECT',
      // 'GET_COLORS',
      // 'GET_COLOR',
      // 'GET_COLORS_FOR_PRODUCT_SELECT',
      // 'GET_COLLECTIONS',
      // 'GET_COLLECTION',
      // 'GET_COLLECTIONS_FOR_PRODUCT_SELECT',
      // 'GET_MANUFACTURERS',
      // 'GET_MANUFACTURER',
      // 'GET_MANUFACTURERS_FOR_PRODUCT_SELECT',
      // 'GET_PRODUCT_CONTAINERS',
      // 'GET_SINGLE_PRODUCT_CONTAINER',
      // 'GET_SHOP_SETTINGS',
      // 'GET_SHIPPING_METHOD',
      // 'GET_PAYMENT_METHOD',
    ],
  })

  const handleOptionChange = (option, value) => {
    // console.log("option ->", option);
    // console.log("value ->", value);
    if (value) {
      // if checked, add to the list
      setSelectedProfiles([...selectedProfiles, option])
    } else {
      // if unchecked
      var filtered = selectedProfiles.filter(function (value, index, arr) {
        return value != option
      })
      setSelectedProfiles(filtered)
    }
  }

  const languageProfileOptions = availableLanguageProfiles.map((option, i) => (
    <LanguageProfileOption
      key={i}
      option={option}
      onChange={handleOptionChange}
    />
  ))

  const handleScriptStart = () => {
    console.log('Starting script...')
    // console.log("translateFromLang :>> ", translateFromLang);
    // console.log("translateToLang :>> ", translateToLang);
    // console.log("selectedProfiles :>> ", selectedProfiles);

    confirm({
      variant: 'danger',
      // catchOnCancel: true,
      title: 'Are you sure?',
      description: ``,
    }).then(() => {
      runLanguageTranslateScript({
        variables: {
          translateFrom: translateFromLang,
          translateTo: translateToLang,
          selectedProfiles: selectedProfiles,
          overwrtieExistingProfiles: overwrtieExistingProfiles,
        },
      })
    })
  }

  //   if (mutationLoading) return <InLineLoader />;
  if (mutationError) return <p>Query Error! {mutationError.message}</p>

  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          <Header
            style={{
              marginBottom: 30,
              boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
            }}
          >
            <Col md={6}>
              <Heading>Language Translate Script</Heading>
            </Col>

            <Col md={6}>
              <Button
                onClick={handleScriptStart}
                isLoading={mutationLoading}
                startEnhancer={() => <Plus />}
                overrides={{
                  BaseButton: {
                    style: ({ $theme, $size, $shape }) => {
                      return {
                        width: '100%',
                        borderTopLeftRadius: '3px',
                        borderTopRightRadius: '3px',
                        borderBottomLeftRadius: '3px',
                        borderBottomRightRadius: '3px',
                      }
                    },
                  },
                }}
              >
                Start Script
              </Button>
            </Col>

            <Row
              style={{
                borderTop: '1px solid grey',
                marginTop: '10px',
                padding: '10px 0',
                flexGrow: 1,
              }}
            >
              <Col lg={6}>
                <Row>
                  <Col lg={6}>
                    <Row>
                      <Col lg={6}> From lang:</Col>
                      <Col lg={6}>
                        <LanguageSwitcherProfile
                          currentLanguage={translateFromLang}
                          setCurrentLanguage={setTranslateFromLang}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row>
                      <Col lg={6}> To lang:</Col>
                      <Col lg={6}>
                        <LanguageSwitcherProfile
                          currentLanguage={translateToLang}
                          setCurrentLanguage={setTranslateToLang}
                        />
                      </Col>
                    </Row>
                  </Col>
                  {/* The overwrite */}
                  <Col lg={12} sm={12} style={{ marginTop: 30 }}>
                    <Row>
                      <Col lg={9} sm={12}>
                        <FieldDetails style={{ padding: 0 }}>
                          If enabled - all translations will be overriden.
                        </FieldDetails>
                      </Col>

                      <Col lg={3} sm={12}>
                        <Checkbox
                          checked={overwrtieExistingProfiles}
                          checkmarkType={STYLE_TYPE.toggle_round}
                          onChange={(e) =>
                            setOverwrtieExistingProfiles(
                              !overwrtieExistingProfiles,
                            )
                          }
                          labelPlacement={LABEL_PLACEMENT.left}
                          overrides={{
                            Root: {
                              style: {
                                float: 'right',
                              },
                            },
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col lg={6}>
                <Row>
                  <Col lg={6}> which ones:</Col>
                  <Col lg={6}>{languageProfileOptions}</Col>
                </Row>
              </Col>
            </Row>

            {mutationLoading && <ProgressBar infinite={true} value={100} />}
          </Header>

          <NotificationsTable data={notifications} />
        </Col>
      </Row>
    </Grid>
  )
}

const LanguageProfileOption = ({ option, onChange }) => {
  const [checked, setChecked] = React.useState(false)

  const handleChange = (e) => {
    setChecked(!checked)
    onChange(option, !checked)
  }
  return (
    <div>
      <Checkbox
        checked={checked}
        onChange={(e) => handleChange(e)}
        labelPlacement={LABEL_PLACEMENT.right}
      >
        {option}
      </Checkbox>
    </div>
  )
}
