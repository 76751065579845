import React, { useEffect, useState } from 'react'
import { Input as BaseInput, SIZE } from 'baseui/input'
import { gql, useQuery, useReactiveVar } from '@apollo/client'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import DrawerSelect from 'components/Select/DrawerSelect'
import { SiteLanguageVar } from 'lib/reactiveVars'
import { extractProfileByLanguage } from 'utils/languages'
import { styled } from 'baseui'
import { expandBorderStyles } from 'baseui/styles'

// TODO Add create mew Manufacturer functionality....

export const GET_COLORS_FOR_SELECT = gql`
  query GET_COLORS_FOR_SELECT {
    findManyColor {
      id
      hex
      background

      color_profiles {
        id
        name
        language
      }
    }
  }
`

const ColorsSelect = ({ onChange, value, placeholder = null }) => {
  // Value is either null Or id of parent category.
  const [optionValue, setOptionValue] = useState(null)
  // === Site language
  const siteLanguage = useReactiveVar(SiteLanguageVar)
  // === GRAPHQL Query to get Languages
  const { loading, error, data } = useQuery(GET_COLORS_FOR_SELECT, {
    // -- Turning off, as referch queries are a lot more efficient
    // fetchPolicy: 'cache-first',
  })

  const [searchTerm, setSearchTerm] = useState()

  useEffect(() => {
    if (value && data && colorsOptions) {
      const selectedValue = colorsOptions.filter(
        (option) => option.id === value,
      )
      setOptionValue(selectedValue)
    }
  }, [value, data])

  if (loading) return <ProgressBar infinite={true} value={100} />
  if (error) return <p>Query Error! {error.message}</p>

  const colorsOptions = data.findManyColor
    .map((color) => {
      // Only return if there is a profile associated with it.
      if (color.color_profiles.length > 0) {
        const languageProfile = extractProfileByLanguage(
          siteLanguage,
          color.color_profiles,
        )

        const optionObject = {
          id: color.id,

          color: color.hex,
          // name: `${color.color_profiles[0].name} (${color.products.length})`,
          label: languageProfile
            ? `${languageProfile.name}`
            : `ID: ${color.id
            } - (Not translated) - ${siteLanguage.toUpperCase()}`,
        }
        return optionObject
      }
    })
    .filter((item) => item !== undefined)

  const handleChange = (value) => {
    // console.log("Value ->", value);
    // Pass the value up (onChange)
    let selectedId = null
    if (value[0]) {
      selectedId = value[0].id
    }
    setOptionValue(value)
    onChange(selectedId)
  }

  const searchChange = (e) => {
    e.preventDefault()
    setSearchTerm(e.target.value.toLowerCase())
  }
  // --- We filter only lowercases...
  const filterSearchOptions = (currentListOfOptions) => {
    return currentListOfOptions.filter((item) =>
      searchTerm ? item.label.toLowerCase().includes(searchTerm) : true,
    )
  }

  return (
    <DrawerSelect
      options={colorsOptions}
      labelKey="name"
      valueKey="id"
      placeholder={placeholder ?? 'Select...'}
      onChange={(e) => handleChange(e.value)}
      value={optionValue}
      searchable={true} // Handling inside searchChange... onInputChange and filterOptions
      clearable={true}
      getOptionLabel={getLabel}
      getValueLabel={getLabel}
      onInputChange={searchChange}
      filterOptions={filterSearchOptions}
    />
  )
}

// ===== Adding colors to selector ===
const ColorSwatch = styled('div', (props: any) => {
  return {
    width: props.$theme.sizing.scale700,
    height: props.$theme.sizing.scale700,
    marginRight: props.$theme.sizing.scale200,
    display: 'inline-block',
    backgroundColor: props.$color,
    verticalAlign: 'middle',
    border: 0,
    borderRadius: '50%',
  }
})
const getLabel = ({ option }: any) => {
  return (
    <React.Fragment>
      {option.color ? <ColorSwatch $color={option.color} /> : null}
      {option.label}
    </React.Fragment>
  )
}

export default ColorsSelect
