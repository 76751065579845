import { styled, useStyletron } from "baseui";
import { Grid as Grids, Row as Rows, Col as Cols } from "react-flexbox-grid";

// export const Grid = styled(Grids, ({ $theme }) => ({
//   &.container,
//   &.container-fluid {
//     padding: 0 15px;
//   }
// }));

export const Grid = styled(Grids, () => ({}));

export const Row = styled(Rows, () => ({
  posistion: "relative",
  // margin: "0 -15px 15px",
  margin: "0 0 15px",
  ":last-child": {
    marginBottom: "0px",
  },
}));

export const Col = styled(Cols, () => ({
  padding: "0 5px",
  // padding: 0,
}));

export const Flex = ({
  children,
  flexDirection = "row",
  justifyContent = "center",
  alignItems = "center",
  overrides = {},
}) => {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        display: "flex",
        flexDirection,
        justifyContent,
        ...overrides,
      })}
    >
      {children}
    </div>
  );
};
