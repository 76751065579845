import { useMutation, useQuery } from '@apollo/client'
import { DURATION, SnackbarProvider, useSnackbar } from 'baseui/snackbar'
import { ArrowLeft, Check } from 'baseui/icon'
import gql from 'graphql-tag'

export const GET_SETTINGS = gql`
  query GET_SETTINGS {
    findManySetting {
      name
      title
      type
      value
      shopSettingId
      description
    }
  }
`
export const UPDATE_SETTINGS = gql`
  mutation UpdateOneShopSetting(
    $data: ShopSettingUpdateInput!
    $where: ShopSettingWhereUniqueInput!
  ) {
    updateOneShopSetting(data: $data, where: $where) {
      id
    }
  }
`

export const useSettings = () => {
  const { data, loading, error } = useQuery(GET_SETTINGS)

  if (loading) return { settings: [], loading, error }
  if (error) return { settings: [], loading, error }

  const settings = data?.findManySetting || []
  const parsetSettings = settings.map((setting: any) => {
    let value = setting.value
    if (setting.type === 'bool') {
      value = value === 'true'
    }
    return {
      ...setting,
      value,
    }
  })

  console.log(parsetSettings)

  return { settings: parsetSettings, loading, error }
}

export const useUpdateSettings = () => {
  const { enqueue } = useSnackbar()
  const [updateSettingsMutation, { data, loading, error }] = useMutation(
    UPDATE_SETTINGS,
    {
      onError: (e) => {
        // For mutation errors...
        console.log('Mutation error!', e)
        console.log('Extracted error!', e.graphQLErrors)

        enqueue(
          {
            message: 'Error!',
            overrides: {
              Message: {
                style: ({ $theme }) => ({
                  color: $theme.colors.red400,
                }),
              },
            },
          },
          DURATION.short,
        )
      },
      onCompleted: (e) => {
        // Handle the success case.
        enqueue(
          {
            message: 'Saving succesful!',
            overrides: {
              Message: {
                style: ({ $theme }) => ({
                  color: $theme.colors.lightGreen,
                }),
              },
            },
          },
          DURATION.short,
        )
      },
    },
  )

  const updateSettings = async (settings: any) => {
    const settingsArr = Object.keys(settings).map((setting) => {
      let settingValue = settings[setting]
      if (typeof settingValue === 'boolean') {
        settingValue = settingValue.toString()
      }
      console.log(settingValue)

      return {
        data: {
          value: { set: settingValue },
        },
        where: {
          name: setting,
        },
      }
    })

    try {
      await updateSettingsMutation({
        variables: {
          data: { settings: { update: settingsArr } },
          where: { id: 1 },
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  return {
    updateSettings,
    data,
    loading,
    error,
  }
}
