import React, { useCallback, useEffect, useState } from 'react'
// import dayjs from "dayjs";
import { withStyle, createThemedUseStyletron, styled } from 'baseui'
import { Grid, Row as Rows, Col as Column } from 'components/FlexBox/FlexBox'
import { useDrawerDispatch } from 'context/DrawerContext'
import Button from 'components/Button/Button'
import { Plus } from 'assets/icons/PlusMinus'
import { Header, Heading } from 'components/Wrapper.style'
import { gql, useMutation, useSubscription } from '@apollo/client'
import { DURATION, useSnackbar } from 'baseui/snackbar'
import { Check } from 'baseui/icon'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher'
import LanguageSwitcherProfile from 'components/LanguageSwitcher/LanguageSwitcherProfile'
import Checkbox, { LABEL_PLACEMENT } from 'components/CheckBox/CheckBox'
import NotificationsTable from 'components/NotificationsTable/NotificationsTable'
import { useConfirmation } from 'context/ConfirmationServiceContext'
import Input from 'components/Input/Input'
import { Box } from 'components/Box/Box'
import { FacebookFeedsBox } from 'components/FacebookFeedsBox/FacebookFeedsBox'

// TODO ---- ADD a toggle for AutoGenerate feed (Enabled / disabled)

export const RUN_FACEBOOK_FEED_SCRIPT = gql`
  mutation RUN_FACEBOOK_FEED_SCRIPT(
    $feedLanguage: Language!
    $shopDomainUrl: String!
  ) {
    runFacebookFeedScript(
      feedLanguage: $feedLanguage
      shopDomainUrl: $shopDomainUrl
    )
  }
`

export const FACEBOOK_SCRIPT_SUBSCRIPTION = gql`
  subscription FACEBOOK_SCRIPT_SUBSCRIPTION {
    facebookScriptSubscription {
      image
      primary
      secondary
    }
  }
`

type CustomThemeT = { red400: string; textNormal: string; colors: any }
const themedUseStyletron = createThemedUseStyletron<CustomThemeT>()

const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',
    ':last-child': {
      marginBottom: 0,
    },
  },
}))
const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px)': {
    alignItems: 'center',
  },
}))

export default function LanguageTranslateScript() {
  //-------
  // TODO Replace with the public url in store
  // const currentUrl = window.location.href.replace("facebook-feed-script", "");
  const currentUrl = 'https://www.'

  const [feedLang, setFeedLang] = useState('lt')
  const [shopDomain, setShopDomain] = useState(currentUrl)

  const [notifications, setNotifications] = useState([])

  const { enqueue } = useSnackbar()
  const confirm = useConfirmation()

  // ==== NOTIFICATIONS SUBSCRIPTION =====
  // const {
  //   data: subscriptionData,
  //   loading: subscriptionLoading,
  // } = useSubscription(FACEBOOK_SCRIPT_SUBSCRIPTION)

  // useEffect(() => {
  //   // Update incoming data of subscriptions
  //   if (subscriptionData?.facebookScriptSubscription) {
  //     // We limit the length of Notifications array, to avoid browser lags...
  //     setNotifications((oldArray) => {
  //       if (oldArray.length > 100) {
  //         oldArray.length = 100
  //       }
  //       return [subscriptionData.facebookScriptSubscription, ...oldArray]
  //     })
  //   }
  //   // console.log("Subscription data ->", notifications);
  // }, [subscriptionData])

  //* ==== Mutation to edita data in database
  const [
    runFacebookFeedScript,
    {
      data: mutationData,
      called,
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(RUN_FACEBOOK_FEED_SCRIPT, {
    onError: (e) => {
      // For mutation errors...
      console.log('Mutation error!', e)
      console.log('Extracted error!', e.graphQLErrors)
      enqueue(
        {
          message: 'Error!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
          },
        },
        DURATION.short,
      )
    },
    onCompleted: (e) => {
      // Handle the success case.
      enqueue(
        {
          message: 'Feed Generation Complete',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
          },
        },
        DURATION.short,
      )
    },
    // Not sure if needed, after we changed - fetchPolicy: "cache-and-network", on all queries
    refetchQueries: ['GET_ALL_FACEBOOK_FEEDS'],
  })

  const handleScriptStart = () => {
    console.log('Starting script...')
    console.log('feedLang :>> ', feedLang)
    console.log('shopDomain :>> ', shopDomain)

    // === Reset notifications
    setNotifications([])

    runFacebookFeedScript({
      variables: {
        feedLanguage: feedLang,
        shopDomainUrl: shopDomain,
      },
    })
  }

  const regenerateScript = (scriptName) => {
    // Deconstruct script name
    const str = scriptName.split('-content-')
    // Second part is the language
    const lang = str[1].replace('.csv', '')
    // first part is the url
    // Get url
    let url = str[0]
      .replace('http-', 'http://')
      .replace('https-', 'https://')
      .replace('www-', 'www.')
      .replace(/\-/g, '/')
      .replace(/\_/g, '.')
    url += url.endsWith('/') ? '' : '/'

    // Set language
    setFeedLang(lang)
    // Set url
    setShopDomain(url)

    console.log('url ->', url)
    console.log('lang ->', lang)
    console.log('Trying to regenerate -->', scriptName)

    // Initiate the start script
    runFacebookFeedScript({
      variables: {
        feedLanguage: lang,
        shopDomainUrl: url,
      },
    })
  }

  //   if (mutationLoading) return <InLineLoader />;
  if (mutationError) return <p>Query Error! {mutationError.message}</p>

  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          <Header
            style={{
              marginBottom: 30,
              boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
            }}
          >
            <Col md={6}>
              <Heading>Facebook Feed Script</Heading>
            </Col>

            <Col md={6}>
              <Button
                onClick={handleScriptStart}
                isLoading={mutationLoading}
                startEnhancer={() => <Plus />}
                overrides={{
                  BaseButton: {
                    style: ({ $theme, $size, $shape }) => {
                      return {
                        width: '100%',
                        borderTopLeftRadius: '3px',
                        borderTopRightRadius: '3px',
                        borderBottomLeftRadius: '3px',
                        borderBottomRightRadius: '3px',
                      }
                    },
                  },
                }}
              >
                Start Script
              </Button>
            </Col>

            <Row
              style={{
                borderTop: '1px solid grey',
                marginTop: '10px',
                padding: '10px 0',
                flexGrow: 1,
              }}
            >
              <Col lg={4}>
                <Row>
                  <Col lg={6}> Language:</Col>
                  <Col lg={6}>
                    <LanguageSwitcherProfile
                      currentLanguage={feedLang}
                      setCurrentLanguage={setFeedLang}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={8}>
                <Row>
                  <Col lg={3}> Shop domain:</Col>
                  <Col lg={9}>
                    <Input
                      value={shopDomain}
                      onChange={(e) => setShopDomain(e.target.value)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            {mutationLoading && <ProgressBar infinite={true} value={100} />}
          </Header>

          <FacebookFeedsBox
            regenerateFunction={regenerateScript}
            mutationLoading={mutationLoading}
          />

          <NotificationsTable data={notifications} />
        </Col>
      </Row>
    </Grid>
  )
}
