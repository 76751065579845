import React, { useEffect, useState } from 'react'
import { withStyle } from 'baseui'
import { Grid, Row as Rows, Col as Column } from 'components/FlexBox/FlexBox'
import Button from 'components/Button/Button'
import { Plus } from 'assets/icons/PlusMinus'
import { Header, Heading } from 'components/Wrapper.style'
import { gql, useMutation, useQuery, useSubscription } from '@apollo/client'
import { DURATION, useSnackbar } from 'baseui/snackbar'
import { Check } from 'baseui/icon'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import Checkbox, {
  LABEL_PLACEMENT,
  STYLE_TYPE,
} from 'components/CheckBox/CheckBox'
import NotificationsTable from 'components/NotificationsTable/NotificationsTable'
import { OnChangeParams, Select, Value } from 'baseui/select'

export const RUN_PRODUCT_IMPORT_SCRIPT = gql`
  mutation RUN_PRODUCT_IMPORT_SCRIPT(
    $update_price: Boolean!
    $provider: String!
  ) {
    runProductImportScript(update_price: $update_price, provider: $provider)
  }
`

export const STOP_PRODUCT_IMPORT_SCRIPT = gql`
  mutation STOP_PRODUCT_IMPORT_SCRIPT {
    stopProductImportScript
  }
`

export const GET_IMPORT_SCRIPT_NOTIFICATIONS = gql`
  query GET_IMPORT_SCRIPT_NOTIFICATIONS {
    getImportScriptNotifications {
      image
      primary
      secondary
      script_finished
    }
  }
`

const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',
    ':last-child': {
      marginBottom: 0,
    },
  },
}))

const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px)': {
    alignItems: 'center',
  },
}))

export default function TestScript() {
  const options = [
    { label: 'King Home', value: 'king_home' },
    { label: 'Richmond', value: 'richmond' },
    { label: 'Maxlight', value: 'maxlight' },
    { label: 'Label51', value: 'label51' },
    { label: 'DeekHorn', value: 'deekhorn' },
    { label: 'Eleonora', value: 'eleonora' },
    { label: 'ByBoo', value: 'byboo' },
    { label: 'Antigo', value: 'antigo' },
    { label: 'Halmar', value: 'halmar' },
    // { label: 'Zumaline', value: 'zumaline' },
    // { label: 'TKLighting', value: 'tklighting' },
  ]

  let filteredOption = options

  const IMPORTS_ENABLED_PROVIDER =
    process.env.REACT_APP_IMPORTS_ENABLED_PROVIDER ?? undefined
  if (IMPORTS_ENABLED_PROVIDER) {
    const providers = IMPORTS_ENABLED_PROVIDER.split(',')
    filteredOption = options.filter((option) =>
      providers.includes(option.value),
    )
  }

  const [provider, setProvider] = useState<Value>([filteredOption[0]])
  const [priceSwitchButton, setPriceSwitchButton] = useState(false)
  const [notifications, setNotifications] = useState([])
  const { enqueue } = useSnackbar()

  const [scriptLoading, setScriptLoading] = useState(false)

  //* ==== Mutation to edita data in database
  const [
    runProductImportScript,
    {
      data: mutationData,
      called,
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(RUN_PRODUCT_IMPORT_SCRIPT, {
    onError: (e) => {
      console.log('Mutation error!', e)
      console.log('Extracted error!', e.graphQLErrors)
      enqueue(
        {
          message: 'Error!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
          },
        },
        DURATION.short,
      )
    },
    onCompleted: (e) => {
      enqueue(
        {
          message: 'Script succesfuly started!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
          },
        },
        DURATION.short,
      )
      //===== Start polling when the mutation finished loading...
      startPolling(5000) // Every 5 seconds we look for new notifications
    },
  })

  //* ==== Mutation to edita data in database
  const [
    stopProductImportScript,
    {
      data: mutationDataScriptStop,
      loading: mutationLoadingScriptStop,
      error: mutationErrorScriptStop,
    },
  ] = useMutation(STOP_PRODUCT_IMPORT_SCRIPT, {
    onError: (e) => {
      console.log('Mutation error!', e)
      console.log('Extracted error!', e.graphQLErrors)
      enqueue(
        {
          message: 'Error!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
          },
        },
        DURATION.short,
      )
    },
    onCompleted: (e) => {
      enqueue(
        {
          message: 'Script succesfuly stopped!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
          },
        },
        DURATION.short,
      )
      //===== Start polling when the mutation finished loading...
      // handleScriptFinish()
      // Handle the finis with notifications in the backend...
    },
  })

  const {
    data: notificationData,
    loading,
    error,
    startPolling,
    stopPolling,
  } = useQuery(GET_IMPORT_SCRIPT_NOTIFICATIONS, {
    // pollInterval: 5000,
  })

  const handleScriptStart = (e) => {
    e.preventDefault()
    e.stopPropagation()

    runProductImportScript({
      variables: {
        update_price: priceSwitchButton,
        provider: provider[0].value,
      },
    })
    setScriptLoading(true)

    setNotifications((oldArray) => {
      if (oldArray.length > 200) {
        oldArray.length = 200
      }

      const startNotification = {
        image: null,
        primary: 'Initializing script',
        secondary: 'Calculating (script will begin shortly)...',
      }

      return [startNotification]
    })
  }

  const handleProvider = ({ value }: OnChangeParams) => {
    setProvider(value)
  }

  const handleScriptFinish = () => {
    //----
    stopPolling()
    setScriptLoading(false)
  }

  const handleScriptStop = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const stopNotification = {
      image: null,
      primary: '======= STOPING SCROPT ===========',
      secondary: 'Wait for processes to stop in the backend...',
    }
    setNotifications((oldArray) => {
      if (oldArray.length > 200) {
        oldArray.length = 200
      }
      return [stopNotification, ...oldArray]
    })

    startPolling(5000)
    console.log('Trying to stop script')
    //--- Initiate stop script query...
    stopProductImportScript()
  }

  useEffect(() => {
    // Update incoming data of subscriptions
    // Update incoming data of subscriptions
    // console.log('notificationData :>> ', notificationData)

    if (notificationData) {
      for (const message of notificationData.getImportScriptNotifications) {
        //We loop all messages and search if scrip_finished
        if (message.script_finished) {
          //--- Script has finished
          handleScriptFinish()
        } else {
          if (scriptLoading === false) {
            setScriptLoading(true)
            startPolling(5000)
          }
        }
      }

      if (scriptLoading) {
        // We limit the length of Notifications array, to avoid browser lags...
        setNotifications((oldArray) => {
          if (oldArray.length > 200) {
            oldArray.length = 200
          }
          return [...notificationData.getImportScriptNotifications, ...oldArray]
        })
      }
    }
    // console.log("Subscription data ->", notifications);
  }, [notificationData])

  // console.log('notifications :>> ', notifications)
  // console.log('Query data ->', notificationData)

  // console.log('scriptLoading :>> ', scriptLoading)

  if (error) return <p>Query Error! {error.message}</p>
  if (mutationError) return <p>Mutation Error! {mutationError.message}</p>

  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          <Header
            style={{
              marginBottom: 30,
              boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
            }}
          >
            <Col md={12}>
              <Heading>Product import script</Heading>
            </Col>

            <Col md={6}>
              <Select
                // TODO use enum from graphql
                options={filteredOption}
                labelKey="label"
                valueKey="value"
                placeholder="Provider"
                value={provider}
                searchable={false}
                onChange={handleProvider}
              />
            </Col>
            <Col md={6}>
              <Button
                onClick={(e) => handleScriptStop(e)}
                // disabled={!scriptLoading}
                // isLoading={scriptLoading}
                // startEnhancer={() => <Plus />}
                kind="secondary"
                overrides={{
                  BaseButton: {
                    style: ({ $theme, $size, $shape }) => {
                      return {
                        width: '45%',
                        borderTopLeftRadius: '3px',
                        borderTopRightRadius: '3px',
                        borderBottomLeftRadius: '3px',
                        borderBottomRightRadius: '3px',
                        marginRight: '4%',
                        backgroundColor: $theme.colors.red400,
                        color: $theme.colors.white,
                        // outline: `${$theme.colors.red400} solid`,
                      }
                    },
                  },
                }}
              >
                Stop
              </Button>

              <Button
                onClick={(e) => handleScriptStart(e)}
                isLoading={scriptLoading}
                startEnhancer={() => <Plus />}
                overrides={{
                  BaseButton: {
                    style: ({ $theme, $size, $shape }) => {
                      return {
                        width: '50%',
                        borderTopLeftRadius: '3px',
                        borderTopRightRadius: '3px',
                        borderBottomLeftRadius: '3px',
                        borderBottomRightRadius: '3px',
                      }
                    },
                  },
                }}
              >
                Start script
              </Button>
            </Col>

            <Col md={12}>
              <Row start="md" middle="md">
                <Col>
                  <span>Do we want to update price?</span>
                </Col>
                <Col>
                  <Checkbox
                    checked={priceSwitchButton}
                    checkmarkType={STYLE_TYPE.toggle_round}
                    onChange={(e) => setPriceSwitchButton(!priceSwitchButton)}
                    labelPlacement={LABEL_PLACEMENT.left}
                    overrides={{
                      Root: {
                        style: {
                          float: 'right',
                        },
                      },
                    }}
                  />
                </Col>
              </Row>
            </Col>

            {scriptLoading && <ProgressBar infinite={true} value={100} />}
          </Header>

          <NotificationsTable data={notifications} />
        </Col>
      </Row>
    </Grid>
  )
}
