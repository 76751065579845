import { CategorySelectModal } from 'containers/WarningModal/CategorySelectModal'
import { ColorSelectModal } from 'containers/WarningModal/ColorSelectModal'
import { CountryOfOriginSelectModal } from 'containers/WarningModal/CountryOfOriginSelectModal'
import { FeaturedSelectModal } from 'containers/WarningModal/FeaturedSelectModal'
import { NumberInputModal } from 'containers/WarningModal/NumberInputModal'
import { ShippingMethodsSelectModal } from 'containers/WarningModal/ShippingMethodsSelectModal'
import {
  WarningModal,
  ConfirmationOptions,
} from 'containers/WarningModal/WarningModal'

import * as React from 'react'
// import { ConfirmationDialog, ConfirmationOptions } from "./ConfirmationDialog";

const ConfirmationServiceContext = React.createContext<
  (options: ConfirmationOptions) => Promise<void | any>
>(Promise.reject)

export const useConfirmation = () =>
  React.useContext(ConfirmationServiceContext)

export const ConfirmationServiceProvider = ({ children, ...props }) => {
  const [
    confirmationState,
    setConfirmationState,
  ] = React.useState<ConfirmationOptions | null>(null)

  const awaitingPromiseRef = React.useRef<{
    resolve: (value) => any
    reject: () => void
  }>()

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options)
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject }
    })
  }

  const handleClose = () => {
    if (confirmationState?.catchOnCancel && awaitingPromiseRef?.current) {
      awaitingPromiseRef.current.reject()
    }

    setConfirmationState(null)
  }

  const handleSubmit = (value) => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(value)
    }

    setConfirmationState(null)
  }

  const type = confirmationState?.type ?? 'WARNING'

  if (type === 'WARNING') {
    return (
      <>
        <ConfirmationServiceContext.Provider
          value={openConfirmation}
          children={children}
        />

        <WarningModal
          open={Boolean(confirmationState)}
          onSubmit={handleSubmit}
          onClose={handleClose}
          {...confirmationState}
        />
      </>
    )
  } else if (type === 'BOOLEAN_SELECT') {
    return (
      <>
        <ConfirmationServiceContext.Provider
          value={openConfirmation}
          children={children}
        />

        <FeaturedSelectModal
          open={Boolean(confirmationState)}
          onSubmit={handleSubmit}
          onClose={handleClose}
          {...confirmationState}
        />
      </>
    )
  } else if (type === 'CATEGORY_SELECT') {
    return (
      <>
        <ConfirmationServiceContext.Provider
          value={openConfirmation}
          children={children}
        />

        <CategorySelectModal
          open={Boolean(confirmationState)}
          onSubmit={handleSubmit}
          onClose={handleClose}
          {...confirmationState}
        />
      </>
    )
  } else if (type === 'COLOR_SELECT') {
    return (
      <>
        <ConfirmationServiceContext.Provider
          value={openConfirmation}
          children={children}
        />

        <ColorSelectModal
          open={Boolean(confirmationState)}
          onSubmit={handleSubmit}
          onClose={handleClose}
          {...confirmationState}
        />
      </>
    )
  } else if (type === 'COUNTRY_OF_ORIGIN_SELECT') {
    return (
      <>
        <ConfirmationServiceContext.Provider
          value={openConfirmation}
          children={children}
        />

        <CountryOfOriginSelectModal
          open={Boolean(confirmationState)}
          onSubmit={handleSubmit}
          onClose={handleClose}
          {...confirmationState}
        />
      </>
    )
  } else if (type === 'SHIPPING_METHOD_SELECT') {
    return (
      <>
        <ConfirmationServiceContext.Provider
          value={openConfirmation}
          children={children}
        />

        <ShippingMethodsSelectModal
          open={Boolean(confirmationState)}
          onSubmit={handleSubmit}
          onClose={handleClose}
          {...confirmationState}
        />
      </>
    )
  } else if (type === 'NUMBER_INPUT') {
    return (
      <>
        <ConfirmationServiceContext.Provider
          value={openConfirmation}
          children={children}
        />

        <NumberInputModal
          open={Boolean(confirmationState)}
          onSubmit={handleSubmit}
          onClose={handleClose}
          {...confirmationState}
        />
      </>
    )
  } else {
    return <p>Some unrecognized modal</p>
  }
}
