import React, { useCallback, useState } from "react";
// import dayjs from "dayjs";
import { withStyle, createThemedUseStyletron } from "baseui";
import { Grid, Row as Rows, Col as Column } from "components/FlexBox/FlexBox";
import { useDrawerDispatch } from "context/DrawerContext";
import Button from "components/Button/Button";
import { Plus } from "assets/icons/PlusMinus";
import { Header, Heading } from "components/Wrapper.style";
import ndjsonStream from "can-ndjson-stream";
import NotificationsTable from "components/NotificationsTable/NotificationsTable";
import Checkbox, {
  LABEL_PLACEMENT,
  STYLE_TYPE,
} from "components/CheckBox/CheckBox";

const serverRestURL = process.env.REACT_APP_API_URL_REST;

type CustomThemeT = { red400: string; textNormal: string; colors: any };
const themedUseStyletron = createThemedUseStyletron<CustomThemeT>();

const Col = withStyle(Column, () => ({
  "@media only screen and (max-width: 767px)": {
    marginBottom: "20px",

    ":last-child": {
      marginBottom: 0,
    },
  },
}));

const Row = withStyle(Rows, () => ({
  "@media only screen and (min-width: 768px)": {
    alignItems: "center",
  },
}));

export default function WooImport() {
  const [totalNumberOfProducts, setTotalNumberOfProducts] = useState(0);
  const [progress, setProgress] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [scanningPage, setScanningPage] = useState(0);

  const [downloadImages, setDownloadImages] = useState(false);

  // console.log("downloadImages in state ->", downloadImages);

  //* =============== IMPORT
  const updateData = (data) => {
    // console.log("In update function -->", data);

    setNotifications((oldArray) => {
      if (oldArray.length > 100) {
        oldArray.length = 100;
      }
      return [data, ...oldArray];
    });

    if (data.primary === "Scan succesfull") {
      setTotalNumberOfProducts(
        (totalNumberOfProducts) => totalNumberOfProducts + 1
      );
    }
    if (data.page) {
      setScanningPage(data.page);
    }
  };

  const testData = useCallback(() => {
    // We initiate data transfer
    setProgress(true);
    fetch(
      `${serverRestURL}import/woocommerce/start-woocommerce-api-connection`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          downloadImages,
        }),
      }
    )
      .then(function (response) {
        return ndjsonStream(response.body);
      })
      .then((exampleStream) => {
        console.log("Secondary response");
        const reader = exampleStream.getReader();
        let read;

        reader.read().then(
          (read = async (result) => {
            if (result.done) {
              // When finished...
              // console.log("Stream is DONE");
              setProgress(false);
              return;
            }

            // console.log("Receiving notification ->");
            // console.log(result.value);

            if (result.value.primary) {
              //  This is a notification message
              await updateData(result.value);
            } else {
              //  Something else, needs to be console logged.
              // console.log(result.value);
            }

            // Initiate reading again
            reader.read().then(read);
          })
        );
      });
  }, [downloadImages]);

  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          <Header
            style={{
              marginBottom: 30,
              boxShadow: "0 0 5px rgba(0, 0 ,0, 0.05)",
            }}
          >
            <Col md={6}>
              <Heading>WooImport</Heading>
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  alignItems: "center",
                }}
              >
                <p>
                  Download and resize images? -{" "}
                  <strong>{downloadImages ? "Yes" : "No"}</strong>
                </p>
                <Checkbox
                  checked={downloadImages}
                  checkmarkType={STYLE_TYPE.toggle_round}
                  onChange={(e) => setDownloadImages(!downloadImages)}
                  labelPlacement={LABEL_PLACEMENT.left}
                  overrides={{
                    Root: {
                      style: {
                        float: "right",
                        marginLeft: "20px",
                      },
                    },
                  }}
                />
              </div>
            </Col>

            <Col md={6}>
              <Row>
                <Col md={12}>
                  <Button
                    onClick={testData}
                    startEnhancer={() => <Plus />}
                    overrides={{
                      BaseButton: {
                        style: ({ $theme, $size, $shape }) => {
                          return {
                            width: "100%",
                            borderTopLeftRadius: "3px",
                            borderTopRightRadius: "3px",
                            borderBottomLeftRadius: "3px",
                            borderBottomRightRadius: "3px",
                          };
                        },
                      },
                    }}
                  >
                    Start Import
                  </Button>
                </Col>
              </Row>
            </Col>
          </Header>

          <NotificationsTable data={notifications} />
        </Col>
      </Row>
    </Grid>
  );
}
