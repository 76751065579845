import * as React from 'react'
import { useEffect } from 'react'
import { Select, Value } from 'baseui/select'
import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { useState } from 'react'
import { Spinner } from 'baseui/icon'
import {
  EnabledLanguagesInSettingsVar,
  SiteLanguageVar,
  StoreWebsiteAddressVar,
} from 'lib/reactiveVars'
import DrawerSelect from 'components/Select/DrawerSelect'

export const GET_SITE_ENABLED_LANGUAGES = gql`
  query GET_SITE_ENABLED_LANGUAGES {
    findFirstShopSetting {
      id
      enabled_languages {
        id
        language
        web_address_enabled
        web_address
        default_language
      }
    }
  }
`

export default function LanguageSwitcher({
  currentLanguage,
  setCurrentLanguage,
}) {
  // === GRAPHQL Query to get Languages
  const {
    loading: loadingLanguages,
    error: errorLanguages,
    data: dataLanguages,
  } = useQuery(GET_SITE_ENABLED_LANGUAGES)

  const siteLanguage = useReactiveVar(SiteLanguageVar)
  const enabledLanguagesInSettings = useReactiveVar(
    EnabledLanguagesInSettingsVar,
  )

  // let value = [{ id: currentLanguage }]
  const [value, setValue] = useState([{ id: currentLanguage }])

  useEffect(() => {
    if (!currentLanguage) {
      if (enabledLanguagesInSettings.length > 0) {
        setValue([{ id: enabledLanguagesInSettings[0].language }])
      }
    } else {
      setValue([{ id: currentLanguage }])
    }
  }, [enabledLanguagesInSettings, currentLanguage])

  // const [value, setValue] = useState([{ id: siteLanguage }])

  if (errorLanguages) {
    return <p>Error! {errorLanguages.message}</p>
  }
  if (loadingLanguages) {
    return <Spinner />
  }

  // ==== Arrange the data...
  const enabledLanguages =
    dataLanguages?.findFirstShopSetting?.enabled_languages ?? null
  let arrayOfEnabledLanguages = [] as any
  let defaultLanguageObject
  if (enabledLanguages) {
    for (const enabledLanguage of enabledLanguages) {
      arrayOfEnabledLanguages.push(enabledLanguage.language)
      if (enabledLanguage.default_language) {
        defaultLanguageObject = enabledLanguage
      }
    }
  }

  if (arrayOfEnabledLanguages.length === 0) {
    if (enabledLanguagesInSettings.length > 0) {
      for (const eLang of enabledLanguagesInSettings) {
        arrayOfEnabledLanguages.push(eLang.language)
      }
    } else {
      // Maybe push EN if no selected?
      // arrayOfEnabledLanguages.push('en')
    }
  }

  // Convert Graphql data to selectable values by switcher
  const languageOptions = arrayOfEnabledLanguages.map((lang) => {
    return { id: lang, label: lang.toUpperCase() }
  })

  // console.log('languageOptions :>> ', languageOptions)
  // const languageOnChange = (lang) => {
  //   //----
  //   // console.log('Changing language...')
  //   SiteLanguageVar(lang)
  //   updateMainShopAddress(lang)
  // }

  const handleChange = (e) => {
    const lang = e.value[0].id
    setCurrentLanguage(lang)
  }

  return (
    <DrawerSelect
      options={languageOptions}
      labelKey="label"
      valueKey="id"
      placeholder="Language profile"
      value={value}
      onChange={handleChange}
      searchable={false}
      clearable={false}
    />
  )
}
