import React, { useCallback, useEffect } from 'react'
import { useDrawerDispatch } from 'context/DrawerContext'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { gql, useMutation, useQuery } from '@apollo/client'
import Button from 'components/Button/Button'
import {
  DrawerTitle,
  FieldDetails,
  Form,
} from 'containers/DrawerItems/DrawerItems.style'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useForm, Controller } from 'react-hook-form'
import { Col, Row } from 'components/FlexBox/FlexBox'
import DrawerBox from 'components/DrawerBox/DrawerBox'
import { FormFields, FormLabel } from 'components/FormFields/FormFields'
import { Label4, LabelSmall, ParagraphSmall } from 'baseui/typography'
import { InLineLoader } from 'components/InlineLoader/InlineLoader'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { removeKey } from 'utils'
import { DURATION, useSnackbar } from 'baseui/snackbar'
import { ArrowLeft, Check } from 'baseui/icon'
import { SITE_SETTINGS } from 'settings/constants'
import { useHistory } from 'react-router-dom'
import { SIZE, Textarea } from 'baseui/textarea'

export const UPDATE_EMBED_CONF_SETTINGS = gql`
  mutation UPDATE_EMBED_CONF_SETTINGS(
    $data: ShopSettingUpdateInput!
    $where: ShopSettingWhereUniqueInput!
  ) {
    updateOneShopSetting(data: $data, where: $where) {
      id
      embed_configurator_settings
    }
  }
`

// We only get the first shop settings, then we update it or create a new if its null.
export const GET_EMBED_CONF_SETTINGS_DATA = gql`
  query GET_EMBED_CONF_SETTINGS_DATA {
    findFirstShopSetting {
      id
      embed_configurator_settings
    }
  }
`

// Validation rules....
const schema = yup.object().shape({
  embed_configurator_settings: yup
    .string()
    .required('Required')
    .test('is-json', 'Invalid JSON format', (value) => {
      try {
        JSON.parse(value)
        return true
      } catch (error) {
        return false
      }
    }),
})

type Props = any
const EmbedConfiguratorSettingsForm: React.FC<Props> = () => {
  // const data = useDrawerState("data");
  // const id = data.postID;

  const { enqueue } = useSnackbar()

  //* ------------------------ DRAWER CONTEXT
  const dispatch = useDrawerDispatch()
  // ===== CLOSE DRAWER CALLBACK
  const closeDrawer = useCallback(
    () => dispatch({ type: 'CLOSE_DRAWER' }),
    [dispatch],
  )

  //* ==== Get all the posts data needed by passing the "data.postID" retrieved from Context
  const {
    data: { findFirstShopSetting: shopSettings } = {},
    loading,
    error,
    refetch,
    fetchMore,
  } = useQuery(GET_EMBED_CONF_SETTINGS_DATA)

  //* ==== Mutation to edita data in database
  const [
    updateConfiguratorSettings,
    {
      data: mutationData,
      called,
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(UPDATE_EMBED_CONF_SETTINGS, {
    onError: (e) => {
      // For mutation errors...
      console.log('Mutation error!', e)
      console.log('Extracted error!', e.graphQLErrors)

      enqueue(
        {
          message: 'Error!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
          },
        },
        DURATION.short,
      )
    },
    onCompleted: (e) => {
      // Handle the success case.
      enqueue(
        {
          message: 'Saving succesful!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
          },
        },
        DURATION.short,
      )
    },
    refetchQueries: ['GET_EMBED_CONF_SETTINGS_DATA'],
  })

  //=========== REACT HOOOK FORM ============
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    control,
  } = useForm({
    resolver: yupResolver(schema),

    defaultValues: {
      shop_settings_id: null,
      embed_configurator_settings: '',
    },
  })

  // =================================

  useEffect(() => {
    // Update Hex in state, once Graphql is finished
    if (shopSettings) {
      setValue('shop_settings_id', shopSettings.id ?? null)
      setValue(
        'embed_configurator_settings',
        shopSettings.embed_configurator_settings,
      )
    }
  }, [setValue, shopSettings]) // We need to include called value (to refresh after mutation)

  const onSubmit = (data) => {
    // Modify form data here =============

    // // Remove __typename keys from the query.
    const modifiedData = removeKey(data, '__typename')
    // ===================================
    console.log('modifiedData (About Us Page) ->', modifiedData, {
      where: {
        id: shopSettings.id,
      },
    })

    let parsedJson = ''
    try {
      parsedJson = JSON.parse(modifiedData.embed_configurator_settings)
    } catch (error) {
      console.log('Error ->', error)
      return
    }

    updateConfiguratorSettings({
      variables: {
        data: {
          embed_configurator_settings: modifiedData.embed_configurator_settings,
        },
        where: {
          id: shopSettings.id,
        },
      },
    })
  }

  let history = useHistory()

  //* ===== always manage the loading state, or error will display "TypeError: Cannot read property 'productContainer' of undefined"
  if (loading) return <InLineLoader />
  if (error) return <p>Query Error! {error.message}</p>

  return (
    <>
      <Button
        onClick={() => history.push(SITE_SETTINGS)}
        overrides={{
          BaseButton: {
            style: {
              padding: '0 10px',
              marginBottom: '10px',
            },
          },
        }}
      >
        <ArrowLeft size={40} />
      </Button>
      <br></br>
      <DrawerTitle>Embed Configutator Settings</DrawerTitle>
      <Form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Row>
          <Col lg={3} sm={12}>
            <DrawerBox sameHeight noBackground>
              <FormLabel>Settings</FormLabel>
              <FieldDetails>
                Use this section to configure the embed configurator appearance.
              </FieldDetails>
            </DrawerBox>
          </Col>
          <Col lg={9} sm={12}>
            <DrawerBox sameHeight>
              <Row>
                <Col lg={12} sm={12}>
                  <FormFields>
                    <Controller
                      name="embed_configurator_settings"
                      control={control}
                      rules={{
                        required: true,
                        shouldUnregister: true,
                      }}
                      render={({ field }) => (
                        <Textarea
                          value={field.value}
                          onChange={field.onChange}
                          clearOnEscape
                          size={SIZE.compact}
                          error={
                            errors.embed_configurator_settings ? true : false
                          }
                          overrides={{
                            Input: {
                              style: ({ $theme }) => ({
                                height: '300px',
                                fontFamily: 'monospace',
                              }),
                            },
                          }}
                        />
                      )}
                    />
                    {errors.embed_configurator_settings && (
                      <Label4 color="red">
                        {errors.embed_configurator_settings.message}
                      </Label4>
                    )}
                  </FormFields>
                  <LabelSmall>Other Settings</LabelSmall>
                  <ParagraphSmall>
                    To make embed configurator properly resize the parameter
                    NEXT_EMBED_CONFIGURATOR_HOSTNAME should be set in .env.local
                    file.
                  </ParagraphSmall>
                </Col>
              </Row>
            </DrawerBox>
          </Col>
        </Row>
        <Button
          type="submit"
          disabled={mutationError}
          isLoading={mutationLoading}
          overrides={{
            BaseButton: {
              style: ({ $theme }) => ({
                width: '90%',
                margin: '15px 15px 15px 15px',
                borderTopLeftRadius: '3px',
                borderTopRightRadius: '3px',
                borderBottomRightRadius: '3px',
                borderBottomLeftRadius: '3px',
              }),
            },
          }}
        >
          Save
        </Button>
      </Form>
    </>
  )
}

export default EmbedConfiguratorSettingsForm
